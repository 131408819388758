import {Helmet} from "react-helmet";
import {withTranslation} from "react-i18next";


const HelmetBlock = ({t}: any) => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{t("gochat.home.Name")} - {t('gochat.InsideFooter.Title')}</title>
                <meta name="description" content={t("Info.Company")}/>
                <meta name="keywords" content={t("Info.Company")}/>
                <meta property="og:title" content={t("Info.Company")}/>
                <meta property="og:type" content="website"/>
                <meta property="og:url" content="https://www.canoecn.com"/>
                <meta property="og:description" content={t("Info.Company")}/>
                <meta name="robots" content="index, follow"/>
                <meta property="og:locale" content="en_EN"/>
                <meta property="og:site_name" content={t("Info.Company")}/>
                <meta property="twitter:title" content={t("Info.Company")}/>
                <meta property="twitter:description" content={t("Info.Company")}/>
                <meta property="twitter:site" content="https://www.canoecn.com"/>
            </Helmet></>

    )
}

export default withTranslation()(HelmetBlock)