import {createGlobalStyle} from "styled-components";

export const Styles = createGlobalStyle`

  @font-face {
    font-family: "Microsoft Yahei";
    font-style: normal;
  }

  @font-face {
    font-family: "Microsoft Yahei";
    font-style: normal;
  }


  body,
  html,
  span,
  a {
    font-family: "Microsoft Yahei";
  }

  html{

    @media only screen and (max-width: 414px) {
      overflow-x:hidden;
    }
  }
  
  body {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    background: #fff;
    overflow-x: hidden;
  }

  a:hover {
    color: #000;
  }

  input,
  textarea {
    border-radius: 4px;
    border: 0;
    background: rgb(241, 242, 243);
    transition: all 0.3s ease-in-out;
    outline: none;
    width: 100%;
    padding: 1rem 1.25rem;

    :focus-within {
      background: none;
      box-shadow: #2e186a 0px 0px 0px 1px;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: "Microsoft Yahei";
    color: #000;
    font-size: 56px;
    line-height: 1.18;

    @media only screen and (max-width: 890px) {
      font-size: 36px;
    }

    @media only screen and (max-width: 414px) {
      font-size: 32px;
    }
  }

  p {
    color: #000;
    font-size: 21px;
    line-height: 1.41;
  }

  h1 {
    font-weight: 600;
  }

  a {
    text-decoration: none;
    outline: none;
    color: #2E186A;

    :hover {
      color: #2e186a;
    }
  }

  *:focus {
    outline: none;
  }

  .about-block-image svg {
    text-align: center;
  }

  .ant-drawer-body {
    display: flex;
    flex-direction: column;
    text-align: left;
    padding-top: 1.5rem;
  }

  .ant-drawer-content-wrapper {
    width: 300px !important;
  }

  @media only screen and (max-width: 768px) {
    .animated {
      /*CSS transitions*/
      -o-transition-property: none !important;
      -moz-transition-property: none !important;
      -ms-transition-property: none !important;
      -webkit-transition-property: none !important;
      transition-property: none !important;
      /*CSS transforms*/
      -o-transform: none !important;
      -moz-transform: none !important;
      -ms-transform: none !important;
      -webkit-transform: none !important;
      transform: none !important;
      /*CSS animations*/
      -webkit-animation: none !important;
      -moz-animation: none !important;
      -o-animation: none !important;
      -ms-animation: none !important;
      animation: none !important;
    }

    [data-aos^="fade"][data-aos^="fade"],
    [data-aos^="zoom"][data-aos^="zoom"] {
      opacity: 1 !important;
    }

    [data-aos="zoom-in"] {
      transform: scale(1) !important;
    }
  }


`;


export const GlobalCss = createGlobalStyle<any>`

  a.ant-btn{
    padding-top: 5px !important;
  }
  
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background-color: #f5f5f5;
    cursor: pointer;
  }

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    border-radius: 10px;
    background-color: #f5f5f5;
    cursor: pointer;
  }

  ::-webkit-scrollbar-thumb {
    //#A95EF6
    background-color: ${props => props.color};
    background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, .4) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .4) 50%, rgba(255, 255, 255, .4) 75%, transparent 75%, transparent);
    -webkit-border-radius: 2em;
    -moz-border-radius: 2em;
    border-radius: 2em;
  }

`


