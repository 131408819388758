import {lazy, Suspense} from "react";
import {Switch, Route} from "react-router-dom";
import routes from "./config";
import {Styles} from "../styles/styles";
import HelmetBlock from "../components/HelmetBlock";
import ScrollToZero from "../common/ScrollToZero";

const Router = () => {

    return (
        <Suspense fallback={null}>
            <HelmetBlock></HelmetBlock>
            <Styles/>
            <ScrollToZero/>
            <Switch>
                {routes.map((routeItem) => {
                    return (
                        <Route
                            key={routeItem.component}
                            path={routeItem.path}
                            exact={routeItem.exact}
                            component={lazy(() => import(`../pages/${routeItem.component}`))}
                        />
                    );
                })}
            </Switch>
        </Suspense>
    );
};

export default Router;
