import {Component, useEffect} from "react";
import {useLocation} from "react-router";

const ScrollToZero = (props: any) => {
    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return <>{
        props.children
    }</>
};

export default ScrollToZero;