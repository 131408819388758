import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import XHR from "i18next-xhr-backend";
import {initReactI18next} from 'react-i18next';

import translationEn from "./locales/en/translation.json";
import translationCn from "./locales/cn/translation.json";

if (!localStorage.getItem('i18nextLng')) {
    localStorage.setItem('i18nextLng', 'cn');
}

i18n
    .use(XHR)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        debug: false,
        fallbackLng: "cn",

        // keySeparator: false,
        interpolation: {
            escapeValue: false,
        },

        resources: {
            en: {
                translations: translationEn,
            },
            cn: {
                translations: translationCn,
            },
        },
        ns: ["translations"],
        defaultNS: "translations"
    });

export default i18n;
