const routes = [
  {
    path: ["/", "/gochat"],
    exact: true,
    component: "GoChat",
  },
  {
    path: ["/gochat", "/gochat"],
    exact: true,
    component: "GoChat",
  }
];

export default routes;
